import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Header = (props) => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  return (
    <div id="hero" className="jumbotron" style={{ alignItems: 'center', justifyContent: 'center' }}>
      <div id="backgroundBokeh" />
      <Container style={{ position: "absolute", alignItems: 'center', marginLeft: isDesktop ? 30 : 0 }}>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            <span className="text-color-main">{"Vivek Kandathil"}</span>
            <p className="text-color-subtitle">
              Full-Stack Developer @ <img src={require("../../images/lisp.svg")} alt={"PDF"} style={{ width: 18, marginLeft: 8 }} />  <a href="https://lisplogics.com/en" style={{ fontSize: 20, textDecoration: 'none', color: 'white' }}>LispLogics</a>
            </p>
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta" style={{ zIndex: 4000 }}>
            <span className="cta-btn cta-btn--hero" style={{ marginRight: 10, zIndex: 4001 }}>
              <Link to="about" smooth duration={1000} style={{ fontSize: 16, zIndex: 4002 }}>
                {t('button.about')}
              </Link>
            </span>
            <span className="cta-btn cta-btn--hero" style={{ marginRight: 10, }}>
              <Link to="projects" smooth duration={1000} style={{ fontSize: 16, zIndex: 4000 }}>
                {t('button.projects')}
              </Link>
            </span>
            <span className="cta-btn cta-btn--hero">
              <a href='/resume' style={{ fontSize: 16, color: 'white', zIndex: 4000 }}>
                {t('button.resume')}
              </a>
            </span>
          </p>
        </Fade>
      </Container>
    </div >

  );
};

export default Header;
