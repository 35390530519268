import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar.js';
import { PortfolioProvider } from '../context/context';
import AboutSelector from "../components/LanguageSelector/AboutSelector.js";

import Particles from "react-tsparticles";
import { particlesConfig } from './Hero/particlesConfig.js';

import { heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';
import colours from '../style/colours.js';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [selectedLanguage, setSelectedLanguage] = React.useState('en');

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  const lngs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'French' }
  };

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer }}>
      <Particles
        id="tsparticles"
        style={{ marginRight: 10, position: 'absolute', height: "100%", top: 80, left: 0 }}
        options={particlesConfig}
      />
      <Navbar selectedLanguage={selectedLanguage} />
      {/* <AboutSelector setSelectedLanguage={setSelectedLanguage} /> */}
      <Hero selectedLanguage={selectedLanguage} />
      <About selectedLanguage={selectedLanguage} />
      <Projects selectedLanguage={selectedLanguage} />
      <Contact selectedLanguage={selectedLanguage} />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
