import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';
import colours from '../../style/colours';

const Projects = (props) => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  return (
    <section id="projects" style={{ color: "white" }}>
      <Container>
        <div className="project-wrapper">
          <Title title={t('button.projects')} />
          {projects.map((project) => {
            const { title, info, info2, url, repo, img, id } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                      {title === "ParkinSync" ?
                          <div>
                            <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -10, marginBottom: 10, }}>
                              <img 
                                  src={require("../../images/mic.png")} 
                                  alt={"A"} 
                                  style={{ 
                                      width: 25,
                                      marginLeft: -5,
                                      marginRight: 3,
                                  }} 
                              />
                              <div style={{ color: '#999', fontWeight: 500, fontSize: 16, }}>
                                Microsoft Imagine Cup Semifinalist
                              </div>
                              
                            </div>
                            <p className="mb-4">
                              <ul>
                                <li>{"A biomedical analytics platform for tremor-suppressing exoskeletons​"}</li>
                                <li>Lead a team of 40 McGill students (<a href="https://www.mcgillbiomechanics.ca/" style={{ fontWeight: 600, color: '#5DC77E' }}>McGill Biomechanics</a>) in the design & development of a full-stack software application (ParkinSync) which analyzes signals from a variety of sensors attached to the hand (EMG, accelerometer, etc.), characterizes potential symptoms of Parkinson's disease, and displays the data/analysis in a user-friendly format.</li>
                              </ul>
                            </p>
                          </div>
                          : <p></p>
                        }
                        {title === "List Maker" ?
                          <div>
                            <p>
                              {t('projects.listmaker.h')}
                            </p>
                            <p className="mb-4">
                              <ul>
                                <li>{t('projects.listmaker.p0')}</li>
                                <li>{t('projects.listmaker.p1')}</li>
                                <li>{t('projects.listmaker.p2')}</li>
                                <li>{t('projects.listmaker.p3')}</li>
                              </ul>
                            </p>
                          </div>
                          : <p></p>
                        }
                        {title === "1000 Push-ups" ?
                          <div>
                            <p>
                              {t('projects.thousandpushups.h')}
                            </p>
                            <p className="mb-4">
                              <ul>
                                <li>{t('projects.thousandpushups.p0')}</li>
                                <li>{t('projects.thousandpushups.p1')}</li>
                                <li>{t('projects.thousandpushups.p2')}</li>
                                <li>{t('projects.thousandpushups.p3')}</li>
                                <li>{t('projects.thousandpushups.p4')}</li>
                              </ul>
                            </p>
                          </div>
                          : <p></p>}
                          {title === "Prompt To Tutorial" ?
                          <div>
                            <p>
                              <ul>
                                <li>{t('projects.prompttotutorial.p0')}</li>
                                <li>{t('projects.prompttotutorial.p1')}</li>
                                <li>{t('projects.prompttotutorial.p2')}</li>
                                <li>{t('projects.prompttotutorial.p3')}</li>
                              </ul>
                            </p>
                          </div>
                          : <p></p>}
                          {title === "Car Catalog" ?
                          <div>
                            <p>
                              {t('projects.carcatalog.h')}
                            </p>
                            <p className="mb-4">{t('projects.carcatalog.p0')}</p>
                          </div>
                          : <p></p>}
                          {title === "Live Equation Editor" ?
                          <div>
                            <p>
                              {t('projects.liveequationeditor.h')}
                            </p>
                          </div>
                          : <p></p>}
                      </div>

                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                      <div style={{ margin: 29 }}>
                        {/* <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={repo}
                        >
                          {t('button.sourcecode')}
                        </a> */}

                        {repo && (title === "List Maker" || title === "1000 Push-ups") && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cta-btn text-color-main"
                            href={title === "List Maker" ? 'https://www.youtube.com/watch?v=ASktNijdeEI' : 'http://vivek.kandathil.ca/files/PushupCounter.mov'}
                          >
                            {t('button.video')}
                          </a>
                        )}
                        {title === "List Maker" && (
                          <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={"https://testflight.apple.com/join/sPEu4v8P"}
                        >
                          {t('button.iosdownload')}
                        </a>
                        )}
                      </div>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
